<template lang="pug">
masked-input.form-control(v-model.string = 'computedValue'
             :mask          = 'maskOptions'
             :placeholder   = 'placeholder'
             type           = 'text')
</template>


<script>
import BigNumber from 'bignumber.js'
import MaskedInput from 'vue-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export default {
  name: 'percent-input',

  components: { MaskedInput },

  props: {
    value: {
      type: [Number, String],
      default: null
    },
    placeholder: {
      type: String,
      default: '0.00 %'
    },
    allowNegative: {
      type: Boolean,
      default: false
    },
    decimalLimit: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      maskOptions: createNumberMask({
        prefix: '',
        suffix: ' %',
        allowDecimal: true,
        decimalLimit: this.decimalLimit,
        thousandsSeparatorSymbol: '',
        allowNegative: this.allowNegative
      }),
      inputValue: ''
    }
  },

  computed: {
    computedValue: {
      get () {
        if (this.inputValue) return this.inputValue
        if (this.value === 0) return '0 %'
        if (!this.value) return ''
        return new BigNumber(this.value).times(100).toString() + ' %'
      },
      set (value) {
        if (value && value.match(/-(_|0|[0-9]+\.) %/)) {
          this.inputValue = value
          if (value === '-_ %') value = null
        } else {
          this.inputValue = ''
        }

        if (value) value = new BigNumber(value.split(' ')[0]).dividedBy(100).toNumber()
        this.$emit('input', value)
      }
    }
  }
}
</script>
